<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <template v-if="$i18n.locale === 'fi'"><PrivacyPolicyInFinnish /></template>
        <template v-if="$i18n.locale === 'en'"><PrivacyPolicyInEnglish /></template>
        <template v-if="$i18n.locale === 'pl'"><PrivacyPolicyInPolish /></template>
        <!-- <h3>13.1. Google</h3>
        <p>Rekisterinpitäjän verkkosivujen analytiikkatietoja kerää Google LLC:n palvelu Google Analytics. Analytiikkatietoja kerätään palvelun kehittämiseksi ja parantamiseksi. Googlen tietosuojakäytäntö on luettavissa osoitteessa: <a href="https://policies.google.com/privacy?hl=fi" target="_blank" rel="noopener">https://policies.google.com/privacy?hl=fi</a></p> -->
      </div>
    </div>
  </div>
</template>

<script>
//import { ref, watch } from "vue";
import PrivacyPolicyInFinnish from "./PrivacyPolicyInFinnish";
import PrivacyPolicyInEnglish from "./PrivacyPolicyInEnglish";
import PrivacyPolicyInPolish from "./PrivacyPolicyInPolish";
//import { useI18n } from "vue-i18n";

export default {
  name: "PrivacyPolicy",
  components: {
    PrivacyPolicyInFinnish,
    PrivacyPolicyInEnglish,
    PrivacyPolicyInPolish
},
  setup() {
    // const { locale } = useI18n({ useScope: 'global' })
    // const lang = ref(locale.value);

    // watch(() => locale.value, (newValue) => {
    //   lang.value = newValue;
    // });

    // const route = useRoute();
    // onMounted(async () => {
    //   getLanguageCode();
    // });
    
    // function getLanguageCode() {
    //   if (route.query.lang === undefined) {
    //     return;
    //   }
    //   lang.value = route.query.lang;
    // }
    // return {
    //   lang,
    // };
  },
};
</script>
