<template>
  <button class="button button3" @click="restart();" :disabled="loading">{{ $t("restartCourse") }}</button>
</template>

<script>
import { resetInternalState, updateRenderStateByCoreState } from './TrainingHandlerProgress'
import TrainingHandler from './TrainingHandler'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const loading = ref(false);
    const { trainingAPI } = TrainingHandler.trainingInstance.get()
    const { t } = useI18n();

    function restart() {
      let text = t('areYouSureYouWantToRestartCourse');
      if (!confirm(text) === true) {
        return;
      }
      
      loading.value = true;
      resetInternalState();
      //trainingAPI.restart(true)
      trainingAPI.restartCourse()
        .then(() => {
          updateRenderStateByCoreState()
          loading.value = false;
        })
    }

    return {
      restart,
      loading: computed(() => loading.value)
    }
  }
}
</script>
