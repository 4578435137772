<template>
  <div>
    <template v-if="!loading">
      <TrainingHeader />
      <!--<div class="secondColor">{{currentCourse}}</div>-->
      <template v-if="renderCourse.courseStructure.length > 0">
        <div id="trainingBody" class="container-fluid">
          <DemoModeToggle v-if="isTestModeAllowed || isTestModeEnabled"/>
          <div id="trainingProgressList">
            <TrainingProgressList />
          </div>

          <!--
                    <div class="secondColor">AnimationState: {{animationState}}</div>
                    <div class="secondColor">isVisible: {{isVisible}}</div>
          -->
          <transition name="fade" mode="out-in" @enter="enter" @after-leave="afterLeave">
            <div v-if="animationState">
              <div v-if="isVisible">
                <template v-if="!renderShowResult">
                  <Training />
                </template>
                <template v-else>
                  <Result />
                </template>
              </div>
            </div>
          </transition>
        </div>
      </template>
      <template v-else>
        <div id="emptyCourse" class="container-fluid">
          <h1>{{ $t("courseHasNoContent") }}</h1>
          <HomeButton :to="{ name: 'Training', params: { trainingId: renderTrainingId } }" />
          <br />
          <br />
        </div>
      </template>
    </template>
    <div class="flexCenter loaderPadding">
      <Loading :loading="loading" />
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed, watch, onActivated } from 'vue'
import { useRoute } from "vue-router";
import TrainingHandler from './TrainingHandler'
import { showCourseResult, renderTrainingId, renderCourse, renderCourseState, renderCourseStructure, renderShowResult, resetState } from './TrainingHandlerProgress'

import Loading from 'Loading'
import TrainingHeader from './TrainingHeader'
import Training from './Training'
import Result from './result/Result'
import TrainingProgressList from './TrainingProgressList'
import HomeButton from '@components/HomeButton'
import DemoModeToggle from './DemoModeToggle'
import { AnswerHandler, UserApi } from '@moods/taitaen-core';
import { TrainingEnums } from "@moods/taitaen-core";

export default {
  name: 'TrainingExecution',
  components: {
    Loading,
    TrainingHeader,
    Training,
    Result,
    TrainingProgressList,
    HomeButton,
    DemoModeToggle
  },
  setup() {
    const route = useRoute();
    const animationState = ref(false)
    const isVisible = ref(false);

    //const { showResult, currentTrainingId, currentCourse, currentCourseStructure } = getTrainingProgress();
    const loading = ref(true);
    //const showCourseResult = ref(false);
    //const resultSequenceNumber = ref(0);

    // const currentTrainingId = ref('');
    // const currentCourse = ref(null);
    // const currentCourseStructure = ref(null);
    // const showResult = ref(false);


    onBeforeMount(() => start())
    onActivated(() => start())

    function start() {
      TrainingHandler.initTrainingInstance();

      // Reset states
      const { eraseAllAnswers } = AnswerHandler()
      eraseAllAnswers();
      resetState();
      // Get and set data
      TrainingHandler.trainingInstance.setup(route.params.trainingId, route.params.courseId)
        .then(() => {
          const { training, course, courseStructure, courseState, showResult } = TrainingHandler.trainingInstance.get()

          //showCourseResult.value = false;
          renderTrainingId.value = training.getId(); // HUOM! PÄIVITÄ STATE
          renderCourse.value = course.get(); // HUOM! PÄIVITÄ STATE
          renderCourseState.value = courseState.get();
          renderShowResult.value = showResult.get();
          checkShowCourseResult();

          renderCourseStructure.value = courseStructure.get(); // HUOM! PÄIVITÄ STATE

          loading.value = false;
        })
        .catch(() => {

        })
    }

    function checkShowCourseResult() {
      const { ProgressState } = TrainingEnums;
      if (renderCourseState.value.courseState.state === ProgressState.PASSED) {
        showCourseResult.value = true;
      }
    }

    watch(() => renderCourseStructure.value, stateChanged);
    watch(() => renderShowResult.value, stateChanged);
    watch(() => showCourseResult.value, stateChanged);
    //watch(() => renderCourseState.value, juttu);

    // function juttu() {
    //   console.log('jutska');
    // }

    // function showCourseResult() {
    //   const { ProgressState } = TrainingEnums;
    //   if (renderCourse.isShowTaskResultBeforeCourseResultEnabled()) {

    //   }
    //   return renderCourseState.value.courseState.state === ProgressState.PASSED;
    // }

    function stateChanged() {
      //const { ProgressState } = TrainingEnums;
      //console.log(ProgressState)
      // console.log('erte', renderCourseState)
      // if (renderCourseState.value) {
      //   console.log(renderCourseState.courseState.state === ProgressState.PASSED, renderShowResult.value)
      // }
      //if (renderCourseState.courseState.state === ProgressState.PASSED) {

      //}
      // showCourseResult.value = false;
      // console.log('HOOO')
      isVisible.value = false
      setTimeout(() => {
        animationState.value = !animationState.value;
      });
    }

    function afterLeave() {
      setTimeout(() => {
        animationState.value = true;
      });
    }
    function enter() {
      isVisible.value = true
    }

    return {
      route,
      loading: computed(() => loading.value),
      isVisible: computed(() => isVisible.value),
      isTestModeAllowed: UserApi.isTestModeAllowed(),
      isTestModeEnabled: UserApi.isTestModeEnabled(),

      renderShowResult: computed(() => renderShowResult.value),

      renderTrainingId: computed(() => renderTrainingId.value),
      renderCourse: computed(() => renderCourse.value),
      //currentTrainingId: training.getId(),
      //currentCourse: course,

      animationState,
      afterLeave,
      enter,
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  animation: fade-in 0.2s;
}
.fade-leave-active {
  animation: fade-in 0.2s reverse;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
