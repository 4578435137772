// Add polyfills
import smoothscroll from 'smoothscroll-polyfill';

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import App from './App.vue'

import * as TaitaenCore from '@moods/taitaen-core'

import './assets/styles/app.scss'

import setAppConfig from './setAppConfig'

import router from './router';

// import translations from './translations.json'
import fi from './translations/fi.json'
import en from './translations/en.json'
import pl from './translations/pl.json'
const translations = {
  fi: fi,
  en: en,
  pl: pl
}

// Create app
const app = createApp(App);

// Kick off the polyfill!
smoothscroll.polyfill();

// SETUP TAITAEN CORE
const { ErrorHandler, Store } = TaitaenCore;

let userLang = Store.getter('User/getLang'); // Get user language
if (userLang === '') { // Default lang is fi
  userLang = 'fi';
}
const i18n = createI18n({
  legacy: false,
  locale: userLang, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: translations, // set locale messages
})

setAppConfig();

// Set error handler
if (process.env.NODE_ENV !== 'development') {
  app.config.errorHandler = (err, vm, info) => {
    ErrorHandler.postError({
      err: ErrorHandler.getTypeError(err),
      vm: vm,
      info: info,
    })
  }
}

app.use(i18n)

app.use(router);

app.mount('#app');

// Redirect to login page if unauthenticated
Store.subscribe('User/token', (newValue) => {
  if (!newValue) {
    router.push({ name: 'Login' }).catch(() => { });
  }
})
