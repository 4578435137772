<template>
  <ul class="languages">
    <li v-for="languageCode in languageCodes" :key="languageCode" @click="changeLocale(languageCode)">
      {{languageCode}}
    </li>
  </ul>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { Store } from "@moods/taitaen-core";

export default {
  props: ["languageCodes"],
  setup() {
    const { locale } = useI18n({ useScope: 'global' })
    function changeLocale(languageCode) {
      locale.value = languageCode;
      Store.commit("User/setLang", languageCode);
    }

    return {
      changeLocale
    };
  },
};
</script>

<style lang="scss" scoped>
  .languages {
    display: flex;
    padding-left: 0;
    list-style: none;

    li {
      padding: 5px 8px 5px 8px;
      cursor: pointer;
    }
  }
</style>