<template>
  <div class="modalContainer">
    <div class="modalOverlay">
      <div class="modalContent">
        <div class="modalContentContainer">
          <div v-if="closeModal" class="closeModalContainer">
            <div @click="closeModal" class="closeModal">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" style="">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"></path>
              </svg>
              <span style="padding-left: 5px; padding-top: 2px">{{ $t("modal.close") }}</span>
            </div>
          </div>
          <div class="modalInnerContent">
            <slot name="header"></slot>
            <slot name="body">
              default body
              <button class="modal-default-button" @click="$emit('close')">OK</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["closeModal"],
};
</script>

<style lang="scss" scoped>
.modalOverlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  transition: opacity 0.3s ease;
}
.modalContent {
  display: table-cell;
  vertical-align: middle;
}
.modalContentContainer {
  max-width: 1030px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.modalInnerContent {
  //max-width: 1000px;
  //margin: 0px auto;
  padding: 20px 30px;
  //background-color: #fff;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
  transition: all 0.3s ease;
  color: var(--theme-color-first);
  border: 1px solid var(--theme-color-first);
  //color: black;
  // max-height: 100vh;
  max-height: 80vh;
  overflow: auto;

  .checkbox {
    border: 2px solid var(--theme-color-first);
  }
}
.closeModalContainer {
  display: flex;
  justify-content: flex-end;
}
.closeModal {
  display: flex;
  padding: 20px;
  color: var(--theme-color-first);
  align-items: center;
  cursor: pointer;
}
</style>
