<template>
  <footer>
    <template v-if="isAuthenticated">
      <div class="container-fluid">
        <div class="row">
          <div class="contentBox col-12 col-lg-6">
            <!-- <TenantFooter/> -->
            <p class="footerlinks">
              <!-- <a href="#" @click.prevent="" class="raquoAfter" @click="showFeedbackForm">{{ $t("giveFeedback.title") }}</a>
              <br> -->
              <router-link class="raquoAfter" :to="{ name: 'PrivacyPolicy'}">{{ $t('privacyPolicy.title') }}</router-link><br>
            </p>
            <p>2022 &copy; Moods Oy</p>
          </div>
          <div class="col-12 col-lg-6">
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 contentBox">
            <!-- <TenantFooter/>
            <br /> -->
            <router-link class="raquoAfter" :to="{ name: 'Login' }">{{ $t('frontPage') }}</router-link>
            <br />
            <router-link class="raquoAfter" :to="{ name: 'PrivacyPolicy'}">{{ $t('privacyPolicy.title') }}</router-link><br>
            <p>2022 &copy; Moods Oy</p>
          </div>
        </div>
      </div>
    </template>
  </footer>
</template>

<script>
//import StatusesList from '@components/login/StatusesList'
import { computed, ref } from "vue";
import { Store } from "@moods/taitaen-core";
//import useFeedbackForm from "@hooks/useFeedbackForm"
//import TenantFooter from "./TenantFooter"

export default {
  name: "Footer",
  components: {
    //TenantFooter
    //StatusesList,
  },
  setup() {
    //const { isFeedbackFormOpened } = useFeedbackForm;
    const isAuthenticated = ref(Store.getter("User/isAuthenticated"));

    Store.subscribe("User/token", (newValue) => {
      isAuthenticated.value = newValue;
    });

    // function showFeedbackForm() {
    //   isFeedbackFormOpened.value = true;
    // }

    return {
      isAuthenticated: computed(() => isAuthenticated.value),
      //showFeedbackForm
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

footer {
  background-color: var(--footer-bg-color);

  padding-top: 50px;
  padding-bottom: 10px;
}
@media only screen and (min-width: $mdMinWidth) {
  .statusesList {
    margin-left: auto;
  }
}
@media only screen and (max-width: 600px) {
  .statusesList {
    flex-flow: column;
  }
}
</style>
