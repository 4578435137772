<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <template v-if="$i18n.locale === 'fi'"><InstructionsInFinnish /></template>
          <template v-if="$i18n.locale === 'en'"><InstructionsInEnglish /></template>
          <template v-if="$i18n.locale === 'pl'"><InstructionsInPolish /></template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstructionsInPolish from "./InstructionsInPolish";
import InstructionsInEnglish from "./InstructionsInEnglish";
import InstructionsInFinnish from "./InstructionsInFinnish";
export default {
  name: "Instructions",
  components: { InstructionsInFinnish, InstructionsInEnglish, InstructionsInPolish },
};
</script>
