<template>
  <div>
    <div v-if="loading" class="flexCenter loaderPadding">
      <Loading :loading="loading" />
    </div>
    <div v-if="!loading">
      <div
        class="container posRelative"
        v-for="(training) in trainings"
        :key="training.id"
      >
        <div class="row trainingRow">
          <div class="col-12 col-md-6 boxPadding trainingDetails">
            <div class="trainingContent">
              <h2>{{training.name}}</h2>
              <div v-html="getIngress(training.confContent)"></div>
              <div class="bottomContainer">
                <router-link class="button" :to="{ name: 'Training', params: { trainingId: training.id } }">{{$t('continueToTraining')}}</router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 defaultBgColor trainingImage" :style="{ backgroundImage: getMainImageUrl(training.confContent) }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUpdate, computed } from 'vue'
import Loading from 'Loading'
import { Store } from "@moods/taitaen-core"
import { getJson, getConfContentValue } from "@utils/General";

export default {
  name: 'Trainings',
  components: {
    Loading,
  },
  setup() {
    const itemsList = ref([]);
    const loading = ref(false);
    const trainings = ref({});

    onBeforeUpdate(() => {
      itemsList.value = []
    })

    onBeforeMount(() => {
      getTrainings()
    })

    function getTrainings() {
      loading.value = true;

      //Store.dispatch('Trainings/getTrainingsWithFullDetails')
      Store.dispatch('Trainings/getTrainings')
      .then((res) => {
        trainings.value = res.data;
        loading.value = false;
      })
      .catch(() => {

      })
    }

    function getIngress(confContent) {
      const conf = getJson(confContent);
      return getConfContentValue(conf, "ingress");
    }

    function getMainImageUrl(confContent) {
      const conf = getJson(confContent);
      const res = getConfContentValue(conf, "mainImage");
      if (res) {
        return "url('" + res + "')";
      }
      return null;
    }

    return {
      itemsList,
      loading: computed(() => loading.value),
      trainings,
      getMainImageUrl,
      getIngress
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

.contentti {
  display: inline-block;

  cursor: pointer;

  .goToDownArrow {
    margin: auto;
  }
}

.goToDownArrow {
  border-top: 25px solid var(--theme-color-first);
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
}

.centerDiv {
  display: flex;
  height: 100%;
}

.trainingRow {
  margin-bottom: 30px;
}

.centering {
  align-self: center;
  max-width: 480px;
  margin-left: auto;
  padding-bottom: 100px;
}
@media only screen and (max-width: $mdMaxWidth) {
  .centerDiv {
    justify-content: center;
  }
}

.trainingDetails {
  background-color: var(--theme-color-fourth);
}

.trainingContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--theme-color-first);
}

.trainingImage {
  min-height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.scrollToDownButton {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;

  text-shadow: 0px 0px 2px black;
}

.fullibakki {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fullViewportHeight {
  height: 100vh;
}

.fullback {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.posRelative {
  position: relative;
}
@media only screen and (max-width: 600px) {
  .statusesList {
    flex-flow: column;
  }
}

@media only screen and (max-width: $smMaxWidth) {
  .centering {
    margin-left: unset;
  }
}
</style>
