import { ref, isRef } from 'vue'
import TrainingHandler from './TrainingHandler'
import useTrainingHeaderElement from './useTrainingHeaderElement';
import { TrainingEnums } from "@moods/taitaen-core";

// Internal
const resultSequenceNumber = ref(0);
const showCourseResult = ref(false);

const renderTrainingId = ref('');
const renderCourse = ref(null);
const renderCourseStructure = ref(null);
const renderShowResult = ref(false);
const renderTaskStatuses = ref(null);

const renderCourseState = ref(null);
const renderTrainingState = ref(null);

export function resetState() {
  resultSequenceNumber.value = 0;
  showCourseResult.value = false;

  renderTrainingId.value = null;
  renderCourse.value = null;
  renderCourseStructure.value = null;
  renderShowResult.value = false;
  renderTaskStatuses.value = {};

  renderCourseState.value = null;
  renderTrainingState.value = null;
}
function resetInternalState() {
  resultSequenceNumber.value = 0;
  showCourseResult.value = false;
}

function updateRenderStateByCoreState() {
  const { courseStructure, showResult, taskStatuses, courseState, trainingState } = TrainingHandler.trainingInstance.get()

  renderCourseStructure.value = courseStructure.get();
  renderShowResult.value = showResult.get();
  renderTaskStatuses.value = taskStatuses.get();
  renderCourseState.value = courseState.get();
  renderTrainingState.value = trainingState.get();
}

function updateRenderState(data) {
  const { courseState, trainingState, course } = TrainingHandler.trainingInstance.get()

  renderCourseStructure.value = data.courseStructure;
  renderShowResult.value = data.showResult;
  renderTaskStatuses.value = data.taskStatuses;
  renderCourseState.value = courseState.get();
  renderTrainingState.value = trainingState.get();

  const { ProgressState } = TrainingEnums;

  // Show task result before course result
  if (renderCourseState.value.courseState.state === ProgressState.PASSED) {
    if (course.isShowTaskResultBeforeCourseResultEnabled() && resultSequenceNumber.value === 0) {
      resultSequenceNumber.value = 1;
    } else {
      showCourseResult.value = true;
    }
  }
}

async function goTo(phase, loading) {
  const { trainingAPI } = TrainingHandler.trainingInstance.get()

  if (isRef(loading)) {
    loading.value = true;
  }
  let data;
  if (phase === 'next') {
    data = await trainingAPI.goToNextCourseStructure();
  } else if (phase === 'previous') {
    data = await trainingAPI.goToPreviousCourseStructure();
  }
  updateRenderState(data);
}

async function next(loading) {
  const { scrollToTop } = useTrainingHeaderElement();
  scrollToTop();
  goTo('next', loading)
}
async function previous(loading) {
  const { scrollToTop } = useTrainingHeaderElement();
  scrollToTop();
  goTo('previous', loading)
}

export {
  showCourseResult,

  renderTrainingId,
  renderCourse,
  renderCourseStructure,
  renderShowResult,
  renderTaskStatuses,

  renderCourseState,
  renderTrainingState,

  next,
  previous,
  updateRenderStateByCoreState,
  resetInternalState
}