<template>
  <div>
    <template v-if="!loading">
      <div v-if="getCourseCompleted()" v-html="getCourseCompleted()"></div>
      <h1 v-else>Olet suorittanut kurssin onnistuneesti</h1>

      <template v-if="trainingState.trainingState === ProgressState.PASSED">
        <div v-html="getTrainingCompleted()"></div>

        <!-- <p><a href="#" @click.prevent="" class="raquoAfter" @click="showFeedbackForm">{{ $t("giveFeedback.title") }}</a></p> -->
      </template>

      <div class="juttu">
        <template v-if="nextCourse">
          <button
            class="button"
            @click="
              startLoading();
              goToNextCourse();
            ">
            {{$t('continueToNextCourse')}}
          </button>
        </template>
        <button
          class="button button3"
          @click="
            restart();
          ">
          {{ $t("restartCourse") }}
        </button>
      </div>
      <br /><br />

      <TrainingHomeButton />
    </template>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TrainingHandler from "../TrainingHandler";
import { resetInternalState, updateRenderStateByCoreState } from "../TrainingHandlerProgress";
import TrainingHomeButton from "../TrainingHomeButton";
import Loading from "Loading";
import { getJson, getConfContentValue } from "@utils/General";
import useFeedbackForm from "@hooks/useFeedbackForm"
import { TrainingEnums, AppConfig } from "@moods/taitaen-core";
import { useI18n } from "vue-i18n";

export default {
  components: {
    TrainingHomeButton,
    Loading,
  },
  setup() {
    const loading = ref(false);
    const { trainingAPI, training, course, trainingState } = TrainingHandler.trainingInstance.get();
    const route = useRoute();
    const router = useRouter();
    const { isFeedbackFormOpened } = useFeedbackForm;
    const { ProgressState } = TrainingEnums;
    const { t } = useI18n();

    const nextCourse = trainingAPI.getNextUncompletedCourse();

    function startLoading() {
      loading.value = true;
    }

    function getCourseCompleted() {
      const confContent = getJson(course.get().confContent);
      return getConfContentValue(confContent, "courseCompleted");
    }

    function getTrainingCompleted() {
      const confContent = getJson(training.get().confContent);
      return getConfContentValue(confContent, "trainingCompleted");
    }

    function showFeedbackForm() {
      isFeedbackFormOpened.value = true;
    }

    function restart() {
      let text = t('areYouSureYouWantToRestartCourse');
      if (!confirm(text) === true) {
        return;
      }

      startLoading();
      resetInternalState();

      //trainingAPI.restart().then(() => {
      trainingAPI.restartCourse().then(() => {
        updateRenderStateByCoreState();
        loading.value = false;
      });

      //Store.dispatch()
      //TrainingHandler.initTrainingInstance();
    }

    function goToNextCourse() {
      router.push({ name: "TrainingExecution", params: { trainingId: training.getId(), courseId: nextCourse.courseId } });
    }

    return {
      environmentMode: AppConfig.get().environmentMode,
      getCourseCompleted,
      getTrainingCompleted,
      trainingState: trainingState.get(),
      ProgressState,
      course,
      route,
      restart,
      nextCourse,
      goToNextCourse,
      startLoading,
      loading: computed(() => loading.value),
      showFeedbackForm
    };
  },
};
</script>

<style lang="scss">
.juttu {
  display: flex;

  > button:not(:last-child) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 650px) {
  .juttu {
    flex-direction: column;

    > button:not(:last-child) {
      margin-right: 0px;
    }
  }
}
</style>