<template>
  <div class="col-md-6 col-lg-4 col-xl-3 box">
    <div class="progressChartBoxColor contentBox" style="height: 100%">
      <ProgressChart :id="'chart_' + training.id" :data="getCourseStates(training.id)" :datasetColors="datasetColors" style="height: 300px" />
      <h3 style="margin-top: 30px; text-align: center">{{ $t("trainingCompletionIndicator") }}</h3>
    </div>
  </div>
</template>

<script>
import ProgressChart from "@tools/ProgressChart";
import { TrainingEnums } from "@moods/taitaen-core";

export default {
  name: "TrainingProgressBox",
  components: {
    ProgressChart,
  },
  props: ["training", "trainingState"],
  setup(props) {
    const { ProgressState } = TrainingEnums;
    const rootStyles = getComputedStyle(document.documentElement);
    const datasetColors = [
      rootStyles.getPropertyValue("--state-indicator-color-passed"), // Completed
      rootStyles.getPropertyValue("--state-indicator-color-in-progress"), // In progress
      rootStyles.getPropertyValue("--state-indicator-color-not-started") // Not started
    ];

    function getBackground() {
      return "rgba(0, 0, 0, 0) linear-gradient(rgb(0, 39, 65) 0%, rgb(0, 76, 124) 100%) repeat scroll 0% 0%";
    }

    function getCourseStates() {
      let training = props.trainingState;
      let not_started = training.courseStates.filter((obj) => obj.courseState.state === ProgressState.NOT_STARTED).length;
      let in_progress = training.courseStates.filter((obj) => obj.courseState.state === ProgressState.IN_PROGRESS).length;
      let passed = training.courseStates.filter((obj) => obj.courseState.state === ProgressState.PASSED).length;

      return [passed / training.courseStates.length, in_progress / training.courseStates.length, not_started / training.courseStates.length];
    }

    return {
      getBackground,
      getCourseStates,
      datasetColors,
    };
  },
};
</script>

<style lang="scss" scoped>
.progressChartBoxColor {
  background-color: var(--theme-color-fifth);
}

.coursesAndProgresses {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-bottom: 15px;
  font-weight: bold;
}
</style>