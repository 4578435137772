<template>
  <h1>Privacy Policy</h1>                      
  <p>Last updated: 16/11/2021</p>
  <p>&nbsp;</p>
  <h2>1. Data controller</h2>
  <p>Name: Moods Oy</p>
  <p>Y-tunnus: 2287262-7</p>
  <p>Address: Yliopistonkatu 35 D, 20100 Turku</p>
  <h2>2. Contact person for issues concerning the register</h2>
  <p>Name: Tiina Koivula</p>
  <p>Phone: +358 50 082 4606</p>
  <p>Email: tiina.koivula@moods.fi</p>
  <h2>3. Name of the register</h2>
  <p>Taitaen User Register</p>
  <h2>4. Purpose for processing personal data</h2>
  <p>Personal data is processed in the service for user authentication and showing information relevant to the user. Users’ data is used in reporting related to the completion of the training. Personal data is also utilised in maintaining the user rights of the service’s publication system. Personal data processing is based on Chapter 2, Section 8.5 and 8.6 of the Personal Data Act.</p>
  <h2>5. Data content of the register</h2>
  <p>The data content of the register consists of case-specifically specified personal data, which usually includes at least an email address. Other information may include, for example, name, address and phone number.</p>
  <h2>6. Other data content of the service</h2>
  <p>The data subject's consent is the legal basis for collecting the service’s other personal data content.</p>
  <h3>6.1. Forms</h3>
  <p>Some forms may require personal data to be entered. Actual contact forms do not store personal data in the register.</p>
  <h3>6.2. Cookies</h3>
  <p>The data controller’s website uses its own cookies and third-party cookies to improve user-friendliness. The cookies collect information, for example, about visits and activity of visitors on the website. Some cookies may be mandatory regarding the data controller’s website functionality.</p>
  <p>We apply authentication-related cookies in connection with logging in. Login cookies are removed within two days unless the “Remember me” box has been ticked.  If “Remember me” has been selected in connection with logging in, the login details shall be stored for two weeks. Cookies related to logging in are removed in connection with logging out.</p>
  <h3>6.3. Content embedded from other websites</h3>
  <p>The website may contain embedded content (for example, videos, images, articles, etc.). Opening embedded content, which has been transferred from other websites, is comparable to the visitor personally visiting the third-party website.</p>
  <p>These websites may collect information about you, use cookies, embed third-party tracking cookies and monitor your interaction with embedded content, including interaction tracking if and when you are logged into the website as a user.</p>
  <h2>7. Regular data sources</h2>
  <p>Personal data is collected in connection with user registrations and, in some instances, in connection with integrated authentication, from the user register, and some forms may require personal data.</p>
  <h2>8. Regular disclosure of data</h2>
  <p>Personal data is processed by the data controller and personal data processors on behalf of the data controller. Personal information is not disclosed to third parties, except regulatory entities in legal circumstances.</p>
  <h2>9. Transferring data outside the EU or the European Economic Area</h2>
  <p>The server used for processing data is owned and managed by a third-party service provider outsourced by the data controller. The service provider outsourced by the data controller is responsible for ensuring that the data remains in the EU or the European Economic Area.</p>
  <h2>10. Retention of personal data</h2>
  <p>Personal data is stored for as long as it is necessary. Unnecessary data shall be deleted.</p>
  <h2>11. Protection principles of the register</h2>
  <p>The data controller’s service details are transferred using an encrypted connection. The service provider of the website server outsourced by the data controller is responsible for the appropriate protection of the server.</p>
  <p>The actual register is also owned and managed by the third-party service provider, and the service provider is responsible for the technical protection of the register. The actual register is subject to authentication with limited user rights.</p>
  <h2>12. Data subject’s right to rectify, review and prohibit data</h2>
  <p>The data subject has the right to review what data has been stored about them in the register. The right to review can be exercised free of charge on an annual basis. The data subject also has the right to request for their data to be rectified or deleted.</p>
  <p>The request to rectify, review or prohibit data must be made in writing to the contact person of the register, and the identity shall be confirmed with an official and valid photo ID document.</p>
  <h2>13. Third-party services and registers</h2>
  <p>Third-party services may collect information about visitors in accordance with their prevailing terms and conditions.</p>
</template>