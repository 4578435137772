<template>
  <Modal class="filledModal" :closeModal="closeModal">
    <template v-slot:header></template>
    <template v-slot:body>
      <div>
        <h2>{{ $t("giveFeedback.title") }}</h2>
        <p v-if="$t('giveFeedback.introduction')">{{ $t("giveFeedback.introduction") }}</p>
        <form action="#" @submit.prevent="sendFeedback">
          <label id="message">{{ $t("giveFeedback.message") }}</label><br>
          <textarea id="message" v-model="message" name="message"/>
          <br><br>
          <input class="button" type="submit" :value="$t('giveFeedback.send')" />
        </form>
        <br>
        <Loading :loading="loading" />
        <template v-if="!loading"><Messages :isSuccess="formResponse.status" :messages="formResponse.message" /><br/></template>
        <button class="button raquoAfter" @click="closeModal">{{ $t('giveFeedback.close') }}</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { computed, ref } from "vue";
import Modal from "@tools/Modal";
import Messages from "Messages";
import Loading from "Loading";
import useFeedbackForm from "@hooks/useFeedbackForm"
import { Store } from '@moods/taitaen-core'
import { useI18n } from 'vue-i18n'

export default {
  name: "FeedbackForm",
  components: {
    Modal,
    Messages,
    Loading
  },
  setup() {
    const { t } = useI18n();

    const message = ref('');
    const formResponse = ref({
      status: false,
      message: "",
    });
    const loading = ref(false);
    const { isFeedbackFormOpened } = useFeedbackForm;

    function sendFeedback() {
      if (!message.value) {
        formResponse.value.message = t('giveFeedback.messageIsEmpty');
        return;
      }
      formResponse.value.message = "";

      Store.dispatch("App/sendForm", {
        //"TemplateId": "<TemplateId>",
        "Fields": {
          "message": message.value,
        },
      })
      .then(() => {
        message.value = "";
        formResponse.value.status = true;
        formResponse.value.message = t('giveFeedback.feedbackSentThankYou');
      })
      .catch(() => {
        formResponse.value.status = false;
        formResponse.value.message = t('giveFeedback.failedToSendFeedback');
      })
    }

    function closeModal() {
      isFeedbackFormOpened.value = false;
    }

    return {
      message,
      formResponse,
      loading: computed(() => loading.value),
      sendFeedback,
      closeModal
    }
  }
}

</script>


<style lang="scss" scoped>
.filledModal {
  height: auto;
}

textarea {
  width: 100%;
  height: 300px;
}
</style>