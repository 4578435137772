<template>
  <canvas ref="chartRef" :id="id" style="width: 100%"></canvas>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useI18n } from "vue-i18n";

Chart.defaults.global.defaultFontFamily = "Arial, sans-serif";

export default {
  name: "ProgressChart",
  props: ["id", "data", "datasetColors", "isRerendered"],
  setup(props) {
    const { t } = useI18n();
    var myChart = null;

    const chartRef = ref(null);

    onMounted(() => {
      if (!chartRef.value) {
        return;
      }
      myChart = new Chart(chartRef.value, getConfig());
    });

    onBeforeUnmount(() => {
      if (myChart) {
        myChart.destroy();
        myChart = null;
      }
    });

    function getConfig() {
      let animation = null;
      if (!props.isRerendered) {
        animation = {
          animateRotate: true,
        };
      }

      return {
        type: "doughnut",
        plugins: [ChartDataLabels],
        data: {
          datasets: [
            {
              data: props.data, // Example value: [40, 10, 50]
              backgroundColor: props.datasetColors,
              // backgroundColor: [
              //     '#11b800',
              //     '#fe7202',
              //     '#ffffff',
              // ],
              //label: 'Dataset 1'
              //hover: false,
            },
          ],
          labels: [t("courseStates.completed"), t("courseStates.inProgress"), t("courseStates.notStarted")],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          hover: { mode: null },
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                let value = data.datasets[0].data[tooltipItem.index] || "";
                let label = data.labels[tooltipItem.index] || "";
                return label + ": " + parseFloat(value * 100, 1).toFixed(1) + " %";
              },
            },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                ctx.chart.config.data.labels[ctx.dataIndex];
                let val = value || "";
                if (!val) {
                  return "";
                }
                return parseFloat(val * 100, 1).toFixed(1) + " %";
              },
              color: "#000",
            },
          },

          animation: animation,
          elements: {
            arc: {
              borderWidth: 0,
            },
            center: {
              text: t("trainingCompletionIndicator"),
              color: "#fff",
              //color: '#FF6384', // Default is #000000
              fontStyle: "Arial, sans-serif",
              //fontStyle: 'Arial', // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 16, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25, // Default is 25 (in px), used for when text wraps
            },
          },
          cutoutPercentage: 60,
          circumference: Math.PI * 1.5,
          rotation: -Math.PI * 1.25,
        },
      };
    }

    return {
      chartRef,
    };
  },
};
</script>
