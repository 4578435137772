<template>
  <div class="progressList" :class="{ fullWidth: fillByPercentages }">
    <template v-if="course.courseStructure && course.courseStructure.length > 0">
      <div class="courseStructureStates">
        <template v-for="courseItem in course.courseStructure" :key="courseItem.id">
          <div class="item" :class="[getStateIndicatorByCourseStructure(courseItem), { currentItem: courseStructure ? courseItem === courseStructure : false }]"></div>
        </template>
      </div>
    </template>
    <div v-if="showLabel" class="courseState">
      <template v-if="course.courseStructure && course.courseStructure.length === 0">{{ $t("courseHasNoContent") }}</template>
      <template v-else-if="courseState.courseState.state === ProgressState.NOT_STARTED">{{ $t("courseStates.courseHasNotStarted") }}</template>
      <template v-else-if="courseState.courseState.state === ProgressState.IN_PROGRESS">{{ $t("courseStates.courseIsInProgress") }}</template>
      <template v-else-if="courseState.courseState.state === ProgressState.PASSED">{{ $t("courseStates.courseHasBeenCompleted") }}</template>
    </div>
  </div>
</template>

<script>
import { TrainingEnums, CourseStructureHelper } from "@moods/taitaen-core";

export default {
  name: "ProgressList",
  props: ["course", "courseState", "courseStructure", "showLabel", "fillByPercentages"],
  setup(props) {
    const { ProgressState } = TrainingEnums;

    function getStateIndicatorByCourseStructure(item) {
      const courseStructureState = CourseStructureHelper.getCourseStructureState(item, props.courseStructure, props.courseState);
      switch (courseStructureState) {
        case ProgressState.NOT_STARTED:
          return "bgStateIndicatorNotStarted";
        case ProgressState.IN_PROGRESS:
          return "bgStateIndicatorInProgress";
        case ProgressState.NOT_PASSED:
          return "bgStateIndicatorNotPassed";
        case ProgressState.PASSED:
          return "bgStateIndicatorPassed";
      }
      return "bgStateIndicatorNotStarted";
    }

    return {
      getStateIndicatorByCourseStructure,
      ProgressState,
    };
  },
};
</script>
<style lang="scss" scoped>
.progressList {
  .courseStructureStates {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .courseState {
    margin-top: 8px;
    text-align: center;
    font-size: 0.875rem;
  }

  .item {
    border-radius: 50px;
    width: 14px;
    height: 14px;
    color: var(--theme-color-third);
    margin-left: 3px;
    margin-right: 3px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &.currentItem {
      //background-color: var(--theme-color-first);
      border: 1px solid green;
    }
  }

  span {
    font-size: 20px;
  }
}

.fullWidth {
  width: 100%;
}
</style>