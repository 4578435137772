<template>
  <div v-if="loading" class="flexCenter loaderPadding">
    <Loading :loading="loading" />
  </div>
  <div v-if="!loading">
    <div class="row">
      <!-- <div class="col-12 col-md-6 col-lg-3 diploma" v-for="(training) in trainings" :key="training.id">
        <div class="diplomaThumbnail defaultBgColor"></div>
        <br>
        {{training.name}}
      </div> -->
      <div class="col">
        <ul>
        <template v-for="trainingState in trainingsStates">
          <li v-if="completedTrainingIds.indexOf(trainingState.trainingId) !== -1" :key="trainingState.trainingId">
            {{getTrainingName(trainingState.trainingId)}}
          </li>
        </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import { Store, TrainingEnums } from "@moods/taitaen-core";
import useTrainings from "@hooks/useTrainings"
import Loading from 'Loading'

export default {
  components: {
    Loading,
  },
  setup() {
    const loading = ref(false);
    const trainingsStates = ref({});
    //const trainings = ref({});
    const { trainings } = useTrainings;
    const { ProgressState } = TrainingEnums;
    const completedTrainingIds = ref([]);

    onBeforeMount(() => {
      getTrainings()
    })

    console.log(completedTrainingIds.value)

    function getTrainingName(trainingId) {
      let training = trainings.value.find((obj) => obj.id === trainingId);
      if (training) {
        return training.name;
      }
      return "<Nimetön>";
    }

    function getTrainings() {
      loading.value = true;

      //Store.dispatch('Trainings/getTrainings')

      //let trainingDataPromise = Store.dispatch('Trainings/getTrainings');
      //let trainingDataPromise = Store.dispatch('Training/getTrainingsState');
      //let trainingStatePromise = Store.dispatch("Training/getTrainingsState");
      //let trainingStatePromise = Store.dispatch("Training/getTrainingsState");
      //let trainingStatePromise = Store.dispatch('Training/getCourseState', trainingId);
      // let trainingStatePromise = Store.dispatch('Training/getCourseState', {
      //     trainingId: trainingId,
      //     courseId: courseId,
      // });
      //Promise.all([trainingDataPromise, trainingStatePromise])
      Store.dispatch('Training/getTrainingsState')
      .then((res) => {
        trainingsStates.value = res.data;

        if (trainingsStates.value) {
          for (let i in trainingsStates.value) {
            Store.dispatch('Training/getTrainingHistory', trainingsStates.value[i].trainingId)
            .then((res) => {
              if (res.data && Array.isArray(res.data) && res.data.length > 0) {
                completedTrainingIds.value.push(res.data[0].trainingId);
              }
              loading.value = false;
            })
            .catch(() => {});
          }
        } else {
          loading.value = false;
        }
      })
      .catch(() => {
        
      })
    }

    return {
      trainings: computed(() => trainings.value),
      trainingsStates: computed(() => trainingsStates.value),
      loading: computed(() => loading.value),
      completedTrainingIds: computed(() => completedTrainingIds.value),
      ProgressState,
      getTrainingName,
    }
  }
}
</script>

<style lang="scss">
.diplomaThumbnail {
  padding-bottom: 100%;
  border: 2px dashed var(--theme-color-second);
}
.diploma {
  margin-bottom: 30px;
}
</style>