<template>
  <div id="login">
    <div class="container contentPadding">
      <div class="row">
        <div id="loginContent" class="col">
          <img id="loginLogo" :src="tenantData.tenantPrimaryLogo" alt="Logo" />
          <Languages :languageCodes="['fi', 'en', 'pl']" />
          <p v-if="$t('login.title')">
            <strong>{{ $t("login.title") }}</strong>
          </p>
          <p v-if="$t('login.message')">{{ $t("login.message") }}</p>
          <br />
          <form action="#" @submit.prevent="login">
            <p v-if="$t('login.signupForTraining')">{{ $t("login.signupForTraining") }}</p>
            <input id="username" type="text" name="username" :placeholder="$t('login.username')" v-model="username" />
            <input id="password" type="password" name="password" :placeholder="$t('login.password')" v-model="password" />
            <div id="rememberMe">
              <label> <input type="checkbox" name="rememberMe" value="1" v-model="rememberMe" /> {{ $t("login.rememberMe") }} </label>
            </div>
            <input class="button" type="submit" :value="$t('login.loginButtonText')" />

            <Loading :loading="loading" />
            <template v-if="!loading"><Messages :isSuccess="false" :messages="errors" /></template>

            <!--<router-link class="raquo" :to="{name: 'ForgotPassword'}"><strong>Unohtuiko salasana?</strong></router-link>-->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import router from "@src/router";
import Loading from "Loading";
import Messages from "Messages";
import * as TaitaenCore from "@moods/taitaen-core";
//import { useResize } from "@hooks/useResize";
import { useRoute } from "vue-router";
import tenantData from "@src/tenantData";
import Languages from "../../components/Languages";
import { useI18n } from 'vue-i18n'

//import '@moods/taitaen-vue-tasks/dist/style.css';

export default {
  name: "Login",
  components: {
    Loading,
    Messages,
    Languages,
  },
  setup() {
    const { t } = useI18n();
    //useI18n.global.locale.value = "en";
    const route = useRoute();
    const { Store, AppConfig } = TaitaenCore;
    //const { set } = useResize();
    //const loginRef = ref(null);
    //const loginContentRef = ref(null);
    //set(loginRef, loginContentRef);

    const username = ref("");
    const password = ref("");
    const rememberMe = ref(0);
    const loading = ref(false);
    const errors = ref({});

    onMounted(async () => {
      getExternalToken();
    });

    function login() {
      //store.commit('user/setRememberMe', rememberMe.value);

      // Get token
      loading.value = true;
      errors.value = {};

      // store.dispatch('user/getToken', {
      //     username: username.value,
      //     password: password.value,
      //     remember_me: rememberMe.value,
      // })
      //Request.User.actions.getToken({
      Store.dispatch("User/getToken", {
        username: username.value,
        password: password.value,
        rememberMe: rememberMe.value,
      })
        .then((res) => {
          loading.value = false;
          if (res.data.errors) {
            errors.value = res.data.errors;
          } else {
            //router.push('/')
            router.push({ name: "Trainings" }).catch((err) => console.error("virhe ohjauksessa", err));
          }
        })
        .catch((err) => {
          loading.value = false;
          errors.value = t("login.loginFailed");

          if (err.response.data && err.response.data.errors) {
            errors.value = err.response.data.errors;
          }
        });
    }

    function externalLogin(method) {
      // build url
      let url = `https://localhost:5200/authentication/${method}?apikey=${process.env.VUE_APP_TENANT_KEY}&returnUrl=${window.location.origin}${window.location.pathname}`;
      window.location = url;
    }

    function getExternalToken() {
      if (route.query.accessToken === undefined || route.query.accessToken.length < 5) {
        return;
      }
      loading.value = true;

      Store.dispatch("User/verifyExternalToken", route.query.accessToken)
        .then((res) => {
          loading.value = false;

          if (res.data.errors) {
            errors.value = res.data.errors;
          } else {
            router.push({ name: "Trainings" }).catch((err) => console.error("virhe ohjauksessa", err));
          }
        })
        .catch((err) => {
          loading.value = false;
          errors.value = t("login.loginFailed");
          if (err.response.data && err.response.data.errors) {
            errors.value = err.response.data.errors;
          }
        });
    }

    return {
      //loginRef,
      //loginContentRef,

      username,
      password,
      loading,
      errors,
      rememberMe,
      login,
      externalLogin,

      environmentMode: AppConfig.get().environmentMode,
      tenantData,
      //tenantLogo: tenantData.tenantLogo
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";
#loginLogo {
  max-width: 100%;
}

#login {
  min-height: 100vh;
  position: relative;
  color: var(--theme-color-first);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-image: url("https://cdn.taitaen.fi/edu-api/08da8bfe-e9d5-46f5-8298-9c6a119364fe/etusivu.jpg?v=1");

  h1 {
    font-weight: bold;
  }

  input {
    &[type="text"],
    &[type="password"] {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #e3e3e3;
      display: block;
      width: 200px;
      //width: 100%;
      //max-width: 265px;
    }
    /*
        padding: 5px;
        border: 1px solid #eee;
        margin-bottom: 10px;
        */

    &[type="submit"] {
      border: 0;
    }
  }
}
#loginLogo {
  margin-bottom: 20px;
}
#loginContent {
  position: relative;
  max-width: 400px;
  margin-top: 40px;
}
@media screen and (min-width: $mdMinWidth) {
  #loginContent {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: $mdMaxWidth) {
  #loginContent {
    margin-top: 0px;
    padding-bottom: 150px;
  }
  #rememberMe {
    margin-top: 10px;
  }
}
</style>
