<template>
  <div>
    <div id="signup">
      <div class="container contentPadding">
        <div class="row">
          <div id="elContent" class="col">
            <img id="signupLogo" :src="tenantData.tenantPrimaryLogo" alt="Logo" />
            <h1>Rekisteröintilomake</h1>
            <p>Sähköpostiosoite toimii käyttäjätunnuksena.</p>
            <form action="#" @submit.prevent="register">
              <input id="username" type="email" name="username" placeholder="Sähköpostiosoite *" v-model="username" />
              <input id="password" type="password" name="password" placeholder="Salasana *" v-model="password" />
              <input id="passwordVerification" type="password" name="password_verification" placeholder="Salasana uudelleen *" v-model="passwordVerification" />
              <input id="personalName" type="text" name="personalName" placeholder="Etu- ja sukunimi *" v-model="personalName" />
              <input id="companyName" type="text" name="companyName" placeholder="Yrityksen nimi *" v-model="companyName" />
              <input id="activationCode" type="text" name="activation_code" placeholder="Aktivointikoodi *" v-model="activationCode" />
              <!-- <p>Tutustuthan <router-link class="raquo" :to="{name: 'PrivacyPolicy'}">tietosuojaselosteeseen</router-link> ennen rekisteröitymistä.</p> -->
              <div class="acceptTerms">
                <!-- <label> -->
                  <input type="checkbox" name="terms" value="1" v-model="terms" /> Hyväksyn tietojeni käsittelyn <span class="openPrivacyPolicy" @click="openPrivacyPolicy">tietosuojaselosteen</span> mukaisesti.
                <!-- </label> -->
              </div>
              <!-- <p>Tutustuthan <span class="" @click="openPrivacyPolicy">tietosuojaselosteeseen</span> ennen rekisteröitymistä.</p> -->
              <input class="button" type="submit" value="Lähetä" />

              <Loading :loading="loading" />
              <template v-if="!loading"><Messages :isSuccess="isSuccess" :messages="messages" /></template>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="isPrivacyPolicyOpened" class="filledModal" :closeModal="closeModal">
      <template v-slot:header></template>
      <template v-slot:body>
        <div>
          <PrivacyPolicy/>
          <button class="button raquoAfter" @click="closeModal">Sulje</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Loading from "Loading";
import Messages from "Messages";
import * as TaitaenCore from "@moods/taitaen-core";
//import { useResize } from "@hooks/useResize";
import tenantData from "@src/tenantData";
import { useRoute } from 'vue-router'
import SuccessfulRegistration from './SuccessfulRegistration'
import PrivacyPolicy from '../shared/PrivacyPolicy'
import Modal from "@tools/Modal";

export default {
  name: "Signup",
  components: {
    Loading,
    Messages,
    PrivacyPolicy,
    Modal
  },
  setup() {
    const { Store } = TaitaenCore;
    //const { set } = useResize();
    //const elRef = ref(null);
    //const elContentRef = ref(null);
    //set(elRef, elContentRef);
    const route = useRoute();
    const isPrivacyPolicyOpened = ref(false);

    const username = ref("");
    const password = ref("");
    const passwordVerification = ref("");
    const personalName = ref("");
    const companyName = ref("");
    const activationCode = ref("");
    const loading = ref(false);
    const messages = ref(null);
    const isSuccess = ref(false);
    const terms = ref(0);

    onMounted(async() => {
      getActivationCode();
    });

    function getActivationCode() {
      if (route.query.activationCode === undefined || route.query.activationCode.length === 0) {
        return;
      }

      activationCode.value = route.query.activationCode;
    }

    function openPrivacyPolicy() {
      isPrivacyPolicyOpened.value = true;
    }

    function register() {
      let isError = false;
      messages.value = [];
      if (!username.value) {
        messages.value.push("Sähköpostiosoite-kenttä ei voi olla tyhjä");
        isError = true;
      }
      if (!password.value) {
        messages.value.push("Salasana-kenttä ei voi olla tyhjä");
        isError = true;
      }
      if (!passwordVerification.value) {
        messages.value.push("Salasana uudelleen -kenttä ei voi olla tyhjä");
        isError = true;
      }
      if (!personalName.value) {
        messages.value.push("Etu- ja sukunimi -kenttä ei voi olla tyhjä");
        isError = true;
      }
      if (!companyName.value) {
        messages.value.push("Yrityksen nimi -kenttä ei voi olla tyhjä");
        isError = true;
      }
      if (!activationCode.value) {
        messages.value.push("Aktivointikoodi ei voi olla tyhjä");
        isError = true;
      }
      if (terms.value === 0) {
        messages.value.push("Hyväksy tietojesi käsittely ennen rekisteröitymistä");
        isError = true;
      }

      if (isError) {
        isSuccess.value = false;
        return;
      }

      loading.value = true;
      messages.value = null;
      //messages.value = {};

      Store.dispatch("User/register", {
        Login: username.value,
        Password: password.value,
        PasswordVerification: passwordVerification.value,
        ActivationCode: activationCode.value,
        CompanyName: companyName.value,
        PersonalName: personalName.value,
        Email: username.value,
      })
      .then((res) => {
        loading.value = false;
        if (res.data.errors) {
          isSuccess.value = false;
          messages.value = res.data.errors;
        } else {
          isSuccess.value = true;
          //messages.value = "Rekisteröityminen onnistui";
          messages.value = <SuccessfulRegistration/>
          //router.push({ name: "Login" }).catch((err) => console.error("virhe ohjauksessa", err));
        }
      })
      .catch((err) => {
        loading.value = false;
        isSuccess.value = false;
        messages.value = "Rekisteröityminen epäonnistui";

        if (err.response.data && err.response.data.errors) {
          messages.value = err.response.data.errors;
        }
      });
    }

    function closeModal() {
      isPrivacyPolicyOpened.value = false;
    }

    return {
      //elRef,
      //elContentRef,
      tenantData,
      loading,
      openPrivacyPolicy,
      isPrivacyPolicyOpened,
      closeModal,

      register,
      isSuccess,
      messages,

      username,
      password,
      passwordVerification,
      personalName,
      companyName,
      activationCode,
      terms,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables";

.filledModal {
  height: auto;
}

#signupLogo {
  width: 250px;
  max-width: 100%;
  margin-bottom: 30px;
}

.openPrivacyPolicy {
  text-decoration: underline;
  cursor: pointer;
}
.acceptTerms {
  margin-top: 20px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#signup {
  position: relative;

  h1 {
    font-weight: bold;
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="email"] {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #e3e3e3;
      display: block;
      //width: 100%;
      //max-width: 265px;
    }
    /*
        padding: 5px;
        border: 1px solid #eee;
        margin-bottom: 10px;
        */

    &[type="submit"] {
      border: 0;
    }
  }
}
#elContent {
  position: relative;
  max-width: 800px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media screen and (max-width: $mdMaxWidth) {
  #elContent {
    margin-top: 0px;
    padding-bottom: 150px;
  }
}
</style>
