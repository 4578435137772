<template>
  <h1>Polityka prywatności</h1>                      
  <p>Ostatnia aktualizacja: 16.11.2021 r.</p>
  <p>&nbsp;</p>
  <h2>1. Administrator danych</h2>
  <p>Nazwisko/Nazwa: Moods Oy</p>
  <p>Biznesowy nr identyfik: 2287262-7</p>
  <p>Adres: Yliopistonkatu 35 D, 20100 Turku</p>
  <h2>2. Osoba kontaktowa w sprawach związanych z rejestrem</h2>
  <p>Imię i nazwisko: Tiina Koivula</p>
  <p>Nr telefonu: +358 50 082 4606</p>
  <p>E-mail: tiina.koivula@moods.fi</p>
  <h2>3. Nazwa rejestru</h2>
  <p>Taitaen User Register</p>
  <h2>4. Cel przetwarzania danych osobowych</h2>
  <p>Dane osobowe są przetwarzane w naszym serwisie na potrzeby uwierzytelniania użytkownika i wyświetlania informacji, które są dla niego istotne. Wykorzystujemy też dane użytkowników w celu tworzenia raportów związanych z ukończeniem szkolenia. Dane osobowe są również wykorzystywane do utrzymania uprawnień użytkowników w systemie publikacji serwisu. Przetwarzanie danych osobowych odbywa się w oparciu o przepisy rozdziału 2 ustępów 8.5 i 8.6 ustawy o ochronie danych osobowych.</p>
  <h2>5. Dane zachowywane w rejestrze</h2>
  <p>Do danych zachowywanych w rejestrze należą określone indywidualnie dane osobowe, które zazwyczaj obejmują przynajmniej adres e-mail. Mogą też do nich należeć takie informacje, jak imię i nazwisko, adres i numer telefonu.</p>
  <h2>6. Inne dane udostępniane serwisowi</h2>
  <p>Podstawę prawną do gromadzenia innego rodzaju danych osobowych przez serwis jest zgoda uzyskana od osoby, której dane dotyczą.</p>
  <h3>6.1. Formularze</h3>
  <p>Niektóre formularze mogą wymagać podania danych osobowych. Nasze formularze kontaktowe nie zachowują danych osobowych w rejestrze.</p>
  <h3>6.2. Pliki cookie</h3>
  <p>Strona internetowa administratora danych zamieszcza własne pliki cookie i pliki cookie podmiotów trzecich, aby zwiększyć komfort użytkownika podczas korzystania z serwisu. Pliki cookie gromadzą informacje dotyczące np. wizyt i aktywności osób odwiedzających nasz serwis. Niektóre pliki cookie mogą być niezbędne dla sprawnego działania strony internetowej administratora danych.</p>
  <p>Korzystamy z plików cookie związanych z uwierzytelnianiem podczas logowania. Pliki cookie zamieszczane przy logowaniu są usuwane w ciągu dwóch dni, chyba że użytkownik zaznaczył opcję „Zapamiętaj mnie” (Remember me).  Jeśli podczas logowania wybrano opcję „Zapamiętaj mnie”, dane wykorzystywane do logowania będą przechowywane przez dwa tygodnie. Pliki cookie związane z logowaniem są usuwane przy wylogowaniu się.</p>
  <h3>6.3. Treści osadzone z innych stron internetowych</h3>
  <p>Strona internetowa może zawierać treści osadzone (np. filmy, obrazy, artykuły itp.). Otwieranie takich treści, które pochodzą z innych stron internetowych należy traktować tak, jakby użytkownik samodzielnie odwiedzał stronę internetową podmiotu trzeciego.</p>
  <p>Strony te mogą gromadzić informacje o użytkowniku, używać plików cookie, osadzać pliki cookie śledzenia należące do podmiotów trzecich i monitorować interakcję z osadzoną treścią, a także obserwować, czy i kiedy odwiedzający jest zalogowany na stronie internetowej jako użytkownik.</p>
  <h2>7. Zwykłe źródła danych</h2>
  <p>Dane osobowe są gromadzone w związku z rejestracją użytkowników, a w niektórych przypadkach, gdy są potrzebne dla zintegrowanego uwierzytelniania, są pozyskiwane z rejestru użytkownika. Niektóre formularze mogą też wymagać podania danych osobowych.</p>
  <h2>8. Zwykłe udostępnianie danych</h2>
  <p>Dane osobowe są przetwarzane przez administratora danych i podmioty przetwarzające dane osobowe w imieniu administratora danych. Dane osobowe nie są ujawniane podmiotom trzecim, z wyjątkiem organów regulacyjnych w sytuacjach, gdy wymaga tego prawo.</p>
  <h2>9. Przekazywanie danych poza UE lub Europejski Obszar Gospodarczy</h2>
  <p>Serwer wykorzystywany do przetwarzania danych jest własnością i jest zarządzany przez zewnętrznego dostawcę usług zleconych przez administratora danych. Dostawca usług zleconych przez administratora danych ma obowiązek zapewnić, by dane te pozostały w obrębie UE lub Europejskiego Obszaru Gospodarczego.</p>
  <h2>10. Przechowywanie danych osobowych</h2>
  <p>Dane osobowe są przechowywane tak długo, jak jest to konieczne. Niepotrzebne dane są usuwane.</p>
  <h2>11. Zasady ochrony danych w rejestrze</h2>
  <p>Dane pochodzące z serwisu administratora danych są przekazywane za pomocą połączenia szyfrowanego. Dostawca usług serwera internetowego zlecanych przez administratora danych jest odpowiedzialny za odpowiednie zabezpieczenie serwera.</p>
  <p>Sam rejestr jest również własnością i jest zarządzany przez zewnętrznego dostawcę usług, który odpowiada za techniczne zabezpieczenie rejestru. Rejestr podlega uwierzytelnieniu z ograniczonymi uprawnieniami użytkownika.</p>
  <h2>12. Prawo do sprostowania, wglądu i zakazu przetwarzania danych</h2>
  <p>Osoba, której dane dotyczą, ma prawo do wglądu w to, jakie dane na jej temat zostały zachowane w rejestrze. Z prawa do wglądu można korzystać bezpłatnie co roku. Osoba, której dane dotyczą, ma też prawo żądać sprostowania lub usunięcia swoich danych.</p>
  <p>Wniosek o sprostowanie, umożliwienie wglądu lub zaprzestanie przetwarzania danych należy złożyć na piśmie do osoby kontaktowej w sprawach dotyczących rejestru, a swoją tożsamość należy potwierdzić za pomocą oficjalnego i ważnego dokumentu tożsamości ze zdjęciem.</p>
  <h2>13. Serwisy i rejestry podmiotów trzecich</h2>
  <p>Serwisy osób trzecich mogą gromadzić informacje na temat odwiedzających je osób, zgodnie z obowiązującymi w nich warunkami.</p>
</template>