<template>
  <form action="#" @submit.prevent="changePassword">
    <input id="password" type="password" name="password" :placeholder="$t('changePassword.password')" v-model="password" />
    <br>
    <input id="newPassword1" type="password" name="newPassword1" :placeholder="$t('changePassword.newPassword')" v-model="newPassword1" />
    <br>
    <input id="newPassword2" type="password" name="newPassword2" :placeholder="$t('changePassword.newPasswordAgain')" v-model="newPassword2" />
    <br>
    <input class="button" type="submit" :value="$t('changePassword.change')" />

    <Loading :loading="loading" />
    <template v-if="!loading"><Messages :isSuccess="isSuccess" :messages="messages" /></template>
  </form>
</template>

<script>
import { ref, computed } from 'vue';
import { Store } from "@moods/taitaen-core";
import Loading from 'Loading'
import Messages from "Messages";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Loading,
    Messages
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(false);
    const messages = ref({});
    const isSuccess = ref(false);

    // Change password
    const password = ref("");
    const newPassword1 = ref("");
    const newPassword2 = ref("");

    function resetFormValues() {
      password.value = "";
      newPassword1.value = "";
      newPassword2.value = "";
    }

    function changePassword() {
      loading.value = true;
      messages.value = {};

      if (password.value === '' || newPassword1.value === '' || newPassword2.value === '') {
        isSuccess.value = false;
        messages.value = t("validations.fillInAllTheFieldsFirst");
        loading.value = false;
        return;
      }

      Store.dispatch("User/updatePassword", {
        Password: password.value,
        NewPassword1: newPassword1.value,
        NewPassword2: newPassword2.value,
      })
        .then((res) => {
          loading.value = false;
          if (res.data.errors) {
            messages.value = res.data.errors;
          } else {
            isSuccess.value = true;
            messages.value = t("changePassword.passwordChangedSuccessfully");

            resetFormValues();
          }
        })
        .catch((err) => {
          loading.value = false;
          isSuccess.value = false;
          messages.value = t("changePassword.passwordChangeFailed");
          
          if (err.response.data) {
            if (err.response.data.errors) {
              messages.value = err.response.data.errors;
            } else if (err.response.data === 'current_password_is_incorrect') {
              messages.value = t("changePassword.wrongPassword");
            }
          }
        });
    }

    return {
      changePassword,
      loading: computed(() => loading.value),

      password,
      newPassword1,
      newPassword2,
      messages,
      isSuccess
    }
  }
}
</script>