<template>
  <div></div>
</template>

<script>
import { Store, AppConfig } from '@moods/taitaen-core'

export default {
  created() {
    if (AppConfig) {
      1+1;
    }
    //Store.dispatch('User/resetState')
    //  .then(this.logoutRequest)
    //  .catch(this.logoutRequest)
    // Store.dispatch('User/removeAnswers')
    //   .then(this.logoutRequest)
    //   .catch(this.logoutRequest)
    this.logoutRequest();
  },
  methods: {
    logoutRequest() {
      Store.dispatch('User/destroyToken')
        .then(this.logout)
        .catch(this.logout)
    },
    logout() {
      Store.resetState();
      this.$router.push({ name: 'Login' }).catch(err => console.error('virhe ohjauksessa', err));
    }
  }
};
</script>
