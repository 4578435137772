<template>
  <Modal>
    <template v-slot:header></template>
    <template v-slot:body>
      <div>
        <p>{{ $t("unexpectedError") }}</p>
        <button class="button" @click="reload">{{ $t("reloadThePage") }}</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@tools/Modal";

export default {
  name: 'CriticalError',
  components: {
    Modal
  },
  setup() {
    function reload() {
      window.location.reload();
    }

    return {
      reload
    }
  },
}
</script>
