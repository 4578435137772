<template>
  <div>
    <template v-if="!loading">
      <template v-if="!isCompleted()">
        <h1>{{$t("result.incorrect.title")}}</h1>
        <p>{{$t("result.incorrect.body")}}</p>
      </template>
      <template v-else>
        <template v-if="getTaskCompleted()">
          <div v-html="getTaskCompleted()"></div>
        </template>
        <template v-else>
          <h1>{{$t("result.correct.title")}}</h1>
          <p>{{$t("result.correct.body")}}</p>
        </template>
      </template>

      <template v-if="!isCompleted()">
        <button type="button" class="button backButton raquoBefore" @click="previous">{{$t('back')}}</button>
      </template>
      <template v-else>
        <button
          type="button"
          class="button raquoAfter"
          @click="
            startLoading();
            next();
          "
        >
          {{$t('next')}}
        </button>
      </template>
    </template>
    <Loading v-else :loading="loading" />

    <br /><br />
    <template v-if="environmentMode === envMode.DEVELOPMENT">
      <RestartCourse />
      <br />
      <br />
    </template>
    <TrainingHomeButton />
  </div>
</template>

<script>
import { computed, ref } from "vue";

import TrainingHandler from "../TrainingHandler";

import { renderTrainingId, renderCourseStructure, next, previous } from "../TrainingHandlerProgress";
import Loading from "Loading";
import RestartCourse from "../RestartCourse";
import TrainingHomeButton from '../TrainingHomeButton'
import { AppConfig } from "@moods/taitaen-core";
import { getJson, getConfContentValue } from "@utils/General";
import envMode from "../../../../envMode";

export default {
  components: {
    TrainingHomeButton,
    Loading,
    RestartCourse,
  },
  setup() {
    const loading = ref(false);

    function getTaskCompleted() {
      const confContent = getJson(renderCourseStructure.value.confContent);
      const res = getConfContentValue(confContent, "taskCompleted");
      if (res) {
        return res;
      }
      return null;
    }

    function startLoading() {
      loading.value = true;
    }
    function isCompleted() {
      const { courseStructure } = TrainingHandler.trainingInstance.get();

      return courseStructure.isComplete();
    }

    return {
      environmentMode: AppConfig.get().environmentMode,
      renderCourseStructure: computed(() => renderCourseStructure.value),
      renderTrainingId: computed(() => renderTrainingId.value),
      isCompleted,
      next,
      previous,
      startLoading,
      getTaskCompleted,
      loading: computed(() => loading.value),
      envMode
    };
  },
};
</script>

<style lang="scss" scoped>
.backButton {
  background-color: var(--theme-color-fourth);
}
</style>