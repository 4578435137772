<template>
  <div>
    <div v-if="!loading" class="container-fluid">
      <div class="row">
        <TrainingProgressBox class="order-2 order-md-1" :training="training" :trainingState="trainingState" />
        <TrainingInfoBox class="order-1 order-md-2" :training="training" />
      </div>
      <div class="row" v-if="isTrainingCompleted">
        <div class="col box"><div class="trainingCompletedBanner">{{ $t("trainingCompletedBannerText") }}</div></div>
      </div>
      <div class="row trainingData">
        <div class="container-fluid">
          <div
            v-if="training.courses.length > 0"
            class="courseList row"
            :ref="
              (el) => {
                itemsList[training.id] = el;
              }
            "
          >
            <template v-if="training.courses.length > 0">
              <CourseBox
                v-for="course in training.courses"
                :set="
                  (courseState = trainingState.courseStates.find(({ courseId }) => courseId === course.id).courseState)
                "
                :course="course"
                :trainingState="trainingState"
                :training="training"
                :key="course.id"
              />
            </template>
          </div>
          <div v-else class="row">
            <div class="col-12 contentBox">
              <br />
              <h2>{{ $t("trainingHasNoContent") }}</h2>
              <br />
              <router-link class="raquoBefore" :to="{ name: 'Trainings' }">
                <small>{{ $t("backToTrainings") }}</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flexCenter loaderPadding">
      <Loading :loading="loading" />
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, onBeforeMount, onBeforeUnmount } from "vue";
import Loading from "Loading";
import { setElementsHeightsAsEqual, resetElementsHeights } from "@utils/General";
import { Store } from "@moods/taitaen-core";
import { useRoute } from "vue-router";

import TrainingInfoBox from "./training/TrainingInfoBox";
import TrainingProgressBox from "./training/TrainingProgressBox";
import CourseBox from "./training/CourseBox";

import Hyphenator from "@utils/Hyphenator";

export default {
  name: "Training",
  components: {
    Loading,
    TrainingProgressBox,
    TrainingInfoBox,
    CourseBox,
  },
  setup() {
    const route = useRoute();

    const itemsList = ref([]);
    const loading = ref(false);
    const training = ref({});
    const trainingState = ref({});
    const isTrainingCompleted = ref(false);
    const isProgressChartRerendered = ref(false);
    const isRerenderNeeded = ref(false);
    const rerenderProgressChart = ref(1);

    var resizeTimer;
    var lastWidth = window.innerWidth;

    onBeforeMount(() => {
      window.addEventListener("resize", onResize);
      getData(route.params.trainingId);
      isCompleted(route.params.trainingId);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });

    function getTrainingBackground(config) {
      return config ? config.themeColor : "secondColor";
    }

    function onResize() {
      if (window.innerWidth === lastWidth) {
        return;
      }
      lastWidth = window.innerWidth;

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        rerenderProgressChart.value++;

        isProgressChartRerendered.value = true;
      }, 250);

      setElementsHeights();
    }

    function setElementsHeights() {
      if (window.innerWidth >= 991) {
        if (!isRerenderNeeded.value) {
          isRerenderNeeded.value = true;
        }
        adjustHeights();
      } else {
        if (isRerenderNeeded.value) {
          isRerenderNeeded.value = false;

          let training, courses;
          for (training in itemsList.value) {
            courses = itemsList.value[training].getElementsByClassName("contentBox");

            resetElementsHeights(courses);
          }
        }
      }
    }

    function getData(training_id) {
      loading.value = true;
      let _trainingData = Store.dispatch("Trainings/getTraining", training_id);
      let _trainingState = Store.dispatch("Training/getTrainingState", training_id);
      Promise.all([_trainingData, _trainingState])
        .then((res) => {
          training.value = res[0].data;
          trainingState.value = res[1].data;
          loading.value = false;

          nextTick(() => {
            setHyphenation();
            setElementsHeights();
          });
        })
        .catch(() => {
        });
    }

    function isCompleted(training_id) {
      Store.dispatch('Training/getTrainingHistory', training_id)
      .then((res) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          isTrainingCompleted.value = true;
        }
      })
      .catch(() => {});
    }

    function setHyphenation() {
      let training, details, courseName, hyphenatedText;
      for (training in itemsList.value) {
        details = itemsList.value[training].getElementsByClassName("details");
        courseName = itemsList.value[training].getElementsByClassName("courseName");

        for (let i = 0; i < details.length; i++) {
          hyphenatedText = Hyphenator.getHyphenatedText(details[i].textContent);
          details[i].textContent = hyphenatedText;
        }
        for (let i = 0; i < courseName.length; i++) {
          hyphenatedText = Hyphenator.getHyphenatedText(courseName[i].textContent);
          courseName[i].textContent = hyphenatedText;
        }
      }
    }

    function adjustHeights() {
      let training, courses;
      for (training in itemsList.value) {
        courses = itemsList.value[training].getElementsByClassName("contentBox");

        setElementsHeightsAsEqual(courses);
      }
    }

    return {
      itemsList,
      loading,
      training: computed(() => training.value),
      trainingState: computed(() => trainingState.value),
      isTrainingCompleted: computed(() => isTrainingCompleted.value),

      getTrainingBackground,

      rerenderProgressChart: computed(() => rerenderProgressChart.value),
      isProgressChartRerendered: computed(() => isProgressChartRerendered.value),
    };
  },
};
</script>
