<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <h1>{{ $t("myDetails") }}</h1>
          <br>
          <h2>{{ $t("completedTrainings") }}</h2>
          <CompletedTrainings/>
          <h2>{{ $t("changePassword.title") }}</h2>
          <ChangePasswordForm/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from './ChangePasswordForm'
import CompletedTrainings from './CompletedTrainings';

export default {
  name: 'MyDetails',
  components: {
    ChangePasswordForm,
    CompletedTrainings
},
  setup() {
  }
};
</script>

<style lang="scss">
</style>